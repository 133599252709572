var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("p", [
        _vm._v(
          "\n    Questions? Please call or email our merchant services team who will be happy to assist you.\n  "
        ),
      ]),
      _c("p", [_vm._v("(208) 500-2151")]),
      _c("p", [_vm._v("payments@corporatetools.com")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }