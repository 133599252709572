<template>
  <div>
    <p>
      Questions? Please call or email our merchant services team who will be happy to assist you.
    </p>
    <p>(208) 500-2151</p>
    <p>payments@corporatetools.com</p>
  </div>
</template>

<script>
export default {
  name: 'MerchantServiceContactInfo',
}
</script>

<style scoped>

</style>
